import React, { useEffect } from 'react'
import connect from './connect'
import SpacingContainerBase from '../../components/layouts/SpacingContainerBase'
import Loader from '../../components/common/Loader'
import { Grid } from '@material-ui/core'
import NothingFoundCard from '../../components/generics/Cards/NothingFoundCard/NothingFoundCardList'
import PaginationSmall from '../../components/common/Paginations/PaginationSmall'
import List from './components/List'
import Header from './components/Header'
import { emptyArray } from '../../utils/arrayUtils'
import { useNavigate } from 'react-router-dom'
import useTabs from '../../hooks/useTabs'

const RequestedQuotes = ({
  requestedQuotes,
  onInit,
  onChangePage,
  onChangeRowPerPage,
  getStaffUsers,
}) => {
  const { items, count, pending, pagination } = requestedQuotes
  const [tab, onChangeTab] = useTabs(1)
  const navigate = useNavigate()

  useEffect(() => {
    getStaffUsers()
  }, [])

  useEffect(() => {
    // Fetch new Quote Requests when tab changes
    onInit({
      params: { taggedAs: tab === 1 ? 'Consulting' : 'Lead' },
    })
  }, [tab, onInit])

  return (
    <SpacingContainerBase>
      <Header tab={tab} onChangeTab={onChangeTab} />
      {pending && <Loader open={pending} />}
      {!emptyArray(items) ? (
        <Grid container spacing={2} direction="column">
          <Grid item xs zeroMinWidth>
            <List items={items} tab={tab} />
          </Grid>

          <Grid item xs zeroMinWidth>
            <PaginationSmall
              page={pagination.page}
              perPage={pagination.perPage}
              count={count}
              onChangePage={onChangePage}
              onChangeRowPerPage={onChangeRowPerPage}
              pending={pending}
            />
          </Grid>
        </Grid>
      ) : !pending && emptyArray(items) ? (
        <Grid item xs zeroMinWidth>
          <NothingFoundCard />
        </Grid>
      ) : null}
    </SpacingContainerBase>
  )
}

export default connect(RequestedQuotes)
