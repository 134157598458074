import { useTrans } from '../../../services/i18n'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import { Paper, SpacingContainer } from '../../../components/common/Containers'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Item from './Item'

const consultingTabColumns = [
  { key: 'date', align: 'left' },
  { key: 'status', align: 'left' },
  { key: 'solicitation-message', align: 'left' },
  { key: 'amount', align: 'left' },
  { key: 'expires', align: 'left' },
  { key: 'assigned-to', align: 'left' },
  { key: 'organization', align: 'center' },
  { key: '', align: 'left' },
]

const leadsTabColumns = [
  { key: 'date', align: 'left', width: '15%' },
  { key: 'solicitation-message', align: 'left', width: '50%' },
  { key: 'organization', align: 'center', width: '30%' },
  { key: '', align: 'left', width: '5%' },
]

export default function List({ items, tab }) {
  const columns = tab === 1 ? consultingTabColumns : leadsTabColumns
  const { trans } = useTrans()
  return (
    <TableContainer component={Paper} radius={9}>
      <SpacingContainer px="5sm3" py={2}>
        <Table style={{ minWidth: 1000 }}>
          <TableHead>
            <TableRow>
              {columns.map(c => (
                <TableCell key={c.key} align={c.align} width={c?.width}>
                  {trans(c.key)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(q => (
              <Item quote={q} columns={columns} key={q.id} />
            ))}
          </TableBody>
        </Table>
      </SpacingContainer>
    </TableContainer>
  )
}
