import React, { useCallback } from 'react'
import connect from '../connect'
import Typography from '../../../components/common/Typography'
import { intlDateFormat } from '../../../utils/dateUtils'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { InlineContainer } from '../../../components/common/Containers'
import StatusChip from './Status'
import QuoteMenu from './QuoteMenu'
import { formatNumberToUsd } from '../../../utils/helpers'
import { OrganizationsCell } from '../../../components/common/Table'

const Item = ({ quote, authUser, columns }) => {
  const organization = {
    organizationId: quote.organizationId,
    orgName: quote.organizationName,
  }

  const hasColumn = useCallback(
    key => columns.some(c => c.key === key),
    [columns]
  )
  
  return (
    <>
      <TableRow>
        {hasColumn('date') && (
          <TableCell align="left">
            <InlineContainer>
              <Typography weight="500" variant="h6">
                {intlDateFormat(quote.createdAt)}
              </Typography>
            </InlineContainer>
          </TableCell>
        )}
        {hasColumn('status') && (
          <TableCell align="left">
            <Typography
              variant="inherit"
              fontFamily="lexend"
              style={{ padding: '0 16px' }}
            >
              <StatusChip status={quote.status} />
            </Typography>
          </TableCell>
        )}
        {hasColumn('solicitation-message') && (
          <TableCell style={{ width: '30%' }} align="left">
            <span>{quote.userMessage}</span>
          </TableCell>
        )}
        {hasColumn('amount') && (
          <TableCell align="left">
            <span style={{ paddingRight: '25px' }}>
              {formatNumberToUsd(quote.amount) || 0}
            </span>
          </TableCell>
        )}
        {hasColumn('expires') && (
          <TableCell align="left">
            <span style={{ paddingRight: '25px' }}>
              {intlDateFormat(quote.expires)}
            </span>
          </TableCell>
        )}
        {hasColumn('assigned-to') && (
          <TableCell align="left">
            <span style={{ paddingRight: '25px' }}>
              {quote.assignedUserName || ''}{' '}
              {quote.assignedUserFamilyName || ''}
            </span>
          </TableCell>
        )}
        {hasColumn('organization') && (
          <TableCell align="center">
            <OrganizationsCell organizations={[organization]} />
          </TableCell>
        )}
        <TableCell align="left">
          <QuoteMenu quote={quote} />
        </TableCell>
      </TableRow>
    </>
  )
}

export default connect(Item)
