export const NOTIFICATIONS = {
  GET_UNREAD: 'notifications.getUnread',
  GET_LIST: 'notifications.getList',
  // FIND_ONE: 'notifications.findOne',
  REMOVE: 'notifications.deleteByIds',
  MARK_AS_ARCHIVED: 'notifications.markAsArchived',
  MARK_AS_READ: 'notifications.markAsRead',
  GET_SETTINGS: 'notifications.getSettings',
  SET_SETTINGS: 'notifications.setSettings',
}

export const CODE_CATEGORIES = {
  GET_LIST_NAICS_AUTOCOMPLETE:
    'codeCategories.filterList.getNaicsListAutocomplete',
  GET_LIST_PSC_AUTOCOMPLETE: 'codeCategories.filterList.getPscListAutocomplete',
}

export const DASHBOARD = {
  GET: 'admin.dashboard.getInformation',
}

export const CLIENTS = {
  GET_LIST: 'admin.clients.getList',
  GET_PENDING_AVATAR: 'admin.clients.getPendingOrgDownloadAvatarUrl',
  CHANGE_AVATAR_STATUS: 'admin.clients.changeAvatarStatus',
}

export const CLIENT_DETAIL = {
  GET_DETAIL: 'admin.clientDetail.getDetail',
  GET_USERS: 'admin.clientDetail.getUsersUsfcr',
  GET_FORMS: 'admin.clientDetail.getForms',
  SAVE_USER_USFCR: 'admin.clientDetail.saveUserUsfcr',
  SAVE_FORM_ORG: 'admin.clientDetail.saveFormOrg',
  DELETE_USER_USFCR: 'admin.clientDetail.deleteUserUsfcr',
  REMOVE_USER_FROM_ORG: 'admin.clientDetail.removeUserFromOrg',
  CHANGE_USFCR_USER_ACCOUNT_STATUS:
    'admin.clientDetail.changeUsfcrUserAccountStatus',
  EDIT_USER_ROLE: 'admin.clientDetail.editUserRole',
  DELETE_FORM_ORG: 'admin.clientDetail.deleteFormOrg',
  DELETE_FILE_ORG: 'admin.clientDetail.deleteFileRequestOrg',
  APPROVE_FILE_ORG: 'admin.clientDetail.approveFileRequestOrg',
  DENY_FILE_ORG: 'admin.clientDetail.denyFileRequestOrg',
  DOWNLOAD_FILE_ORG: 'admin.clientDetail.downloadFileRequestOrg',
  GET_FOLDERS_BY_FOLDER_ID: 'admin.clientDetail.getFoldersByFolderId',
  SAVE_FILE_REQUEST: 'admin.clientDetail.saveFileRequest',
  UPDATE_FILE_REQUEST: 'admin.clientDetail.updateFileRequest',
  ACTIVE_DEACTIATE_ORG: 'admin.clientDetail.activeDeactiveOrganization',
  GET_HISTORY_EVENTS: 'admin.clientDetail.getHistoryEvents',
  SAVE_BILLING_SETTINGS: 'admin.clientDetail.updateBillingInformation',
  SAVE_PERMISSIONS: 'admin.clientDetail.updatePermissions',
  CREATE_FOLDER: 'admin.clientDetail.createFolder',
  ASSOC_USER: 'admin.clientDetail.assocUserToOrg',
  UPDATE_NUMBER_LICENSES: 'admin.clientDetail.updateOrganizationLicenses',
  SAVE_CUSTOMIZATION: 'admin.partnership.addBrandingColor',
  UPDATE_CLAIMED_VENDOR: 'admin.clientDetail.updateClaimedVendor',
  REMOVE_CLAIMED_VENDOR: 'admin.clientDetail.removeClaimedVendor',
  REFRESH_CLAIMED_VENDOR: 'admin.clientDetail.getVendorFromSam',
  UPDATE_EXPORTS_CONFIGURATION: 'admin.clientDetail.updateExportsConfiguration',
  GET_CATEGORIES: 'admin.clientDetail.getCategories',
  GET_CATEGORIES_FILES: 'admin.clientDetail.getCategoryFiles',
  CREATE_SUBSIDIARY_ORGANIZATION: 'organization.addOrganization',
  UPDATE_PARENT_ORGANIZATION: 'organization.updateParentOrganization',
  DELETE_ORGANIZATION: 'admin.deleteOrganization',
  MERGE_ORGANIZATION: 'admin.mergeOrganization',
  GET_JSON_FORM_URL: 'admin.clientDetail.getSignedURLJSONForm',
}

export const USFCR_STAFF = {
  GET_LIST: 'admin.usfcrStaff.getList',
  ACTIVE_USER_STAFF: 'admin.usfcrStaff.activeDeactivateUserStaff',
  REMOVE_USER_STAFF: 'admin.usfcrStaff.removeUserStaff',
  ADD_USER: 'admin.usfcrStaff.addUser',
  EDIT_USER: 'admin.usfcrStaff.editUser',
  GET_USERS_NO_IS_USFCR: 'admin.usfcrStaff.getUsersNoIsUsfcr',
  FIND_ONE: 'admin.usfcrStaff.findOne',
  ASSIGN_ORGS: 'admin.usfcrStaff.assignOrganizations',
  GET_ASSIGNED_ORGS: 'admin.usfcrStaff.getAssignedOrgByUser',
  REMOVE_USER_ORG: 'admin.usfcrStaff.removeUserOrg',
  GET_ACTIVITY_HISTORY: 'admin.usfcrStaff.getActivityHistoryByUser',
}

export const USERS = {
  GET_LIST: 'admin.registeredUsers.getList',
  DELETE: 'admin.registeredUsers.delete',
  DISABLE_TWO_FACTOR_AUTH: 'admin.registeredUsers.disableTwoFactorAuth',
  FIND_ONE: 'admin.registeredUsers.findOne',
  SET_NOTIFICATION_SETTINGS: 'user.setEmailNotificationSettings',
}

export const RELEASES = {
  GET_LIST: 'admin.releases.getList',
  FIND_ONE: 'admin.releases.findOne',
  CHANGE_STATUS: 'admin.releases.changeStatus',
  DELETE: 'admin.releases.deleteRelease',
  CREATE: 'admin.releases.createRelease',
  UPDATE: 'admin.releases.updateRelease',
}

export const REQUESTED_QUOTES = {
  GET_LIST: 'admin.requestedQuotes.getList',
  FIND_ONE: 'admin.requestedQuotes.findOne',
  CHANGE_STATUS: 'admin.requestedQuotes.changeStatus',
  SAVE_RESPONSE: 'admin.requestedQuotes.saveResponse',
  ASSIGN_USER: 'admin.requestedQuotes.assignUser',
  GET_STAFF_USERS: 'admin.requestedQuotes.getStaffUsers',
  // DELETE: 'admin.requestedQuotes.deleteRequestedQuote',
  // CREATE: 'admin.requestedQuotes.createRequestedQuote',
  // UPDATE: 'admin.requestedQuotes.updateRequestedQuote',
}

export const REQUEST_TEMPLATES = {
  GET_LIST: 'admin.requestTemplates.getList',
  GET_ALL_TEMPLATES: 'admin.requestTemplates.getAllTemplates',
  GET_RESOURCES_BY_TEMPLATE: 'admin.requestTemplates.getResourcesByTemplate',
  FIND_ONE: 'admin.requestTemplates.findOne',
  SAVE_REQUEST_TEMPLATE: 'admin.requestTemplates.saveRequestTemplate',
  DELETE: 'admin.requestTemplates.deleteRequestTemplate',
  SAVE_REQUEST_IN_ORGANIZATION:
    'admin.requestTemplates.saveRequestInOrganization',
}

export const EMAIL_TEMPLATES = {
  GET_LIST: 'admin.emailTemplates.getList',
  FIND_ONE: 'admin.emailTemplates.findOne',
  SAVE: 'admin.emailTemplates.save',
}

export const REQUEST_TEMPLATE_FILES = {
  GET_LIST: 'admin.requestTemplateFiles.getList',
  FIND_ONE: 'admin.requestTemplateFiles.findOne',
  SAVE_REQUEST_TEMPLATE: 'admin.requestTemplateFiles.saveRequestTemplateFile',
  DELETE: 'admin.requestTemplateFiles.deleteRequestTemplateFile',
}

export const REQUEST_TEMPLATE_FORMS = {
  GET_LIST: 'admin.requestTemplateForms.getList',
  FIND_ONE: 'admin.requestTemplateForms.findOne',
  SAVE_REQUEST_TEMPLATE: 'admin.requestTemplateForms.saveRequestTemplateForm',
  DELETE: 'admin.requestTemplateForms.deleteRequestTemplateForm',
}

export const SUBSCRIPTION_SETTINGS = {
  GET_FEATURES: 'admin.globalSettings.getDefaultFeatures',
  GET_SETTINGS: 'admin.globalSettings.getSubscriptionSettings',
  UPDATE_API_KEY: 'admin.globalSettings.updateSamApiKey',
  CHANGE_TAB: 'admin.globalSettings.changeTab',
  SAVE_SETTINGS: 'admin.globalSettings.saveSubscriptionSettings',
  GET_KEEP_TRASHED_NOTIFICATIONS:
    'admin.globalSettings.getKeepTrashedNotification',
  UPDATE_KEEP_TRASHED_NOTIFICATIONS:
    'admin.globalSettings.updateKeepTrashedNotification',
  UPDATE_SETTINGS: 'admin.globalSettings.updateSubscriptionSettings',
  UPDATE_ORGANIZATION_SETTINGS:
    'admin.globalSettings.updateOrganizationSettings',
}

export const GLOBAL_SETTINGS = {
  GET_FEATURES: 'admin.globalSettings.getDefaultFeatures',
  SAVE_FEATURES: 'admin.globalSettings.saveFeatures',
  SAVE_DEFAULT_SAP_REQUESTS: 'admin.globalSettings.saveDefaultSAPRequests',
  SAVE_DEFAULT_SAM_REQUESTS: 'admin.globalSettings.saveDefaultSAMRequests',
  GET_FORMSTACK_FORMS: 'admin.globalSettings.getAllFormstackForms',
  CHANGE_STATUS_FORM: 'admin.globalSettings.changeStatusForm',
  DELETE_FORM: 'admin.globalSettings.deleteForm',
  DELETE_TEMPLATE_SAP: 'admin.globalSettings.deleteTemplateSAP',
  DELETE_TEMPLATE_SAM: 'admin.globalSettings.deleteTemplateSAM',
  GET_ADJUSTMENT_FORMS: 'admin.globalSettings.getAdjustmentForms',
  GET_KEEP_TRASHED_NOTIFICATIONS:
    'admin.globalSettings.getKeepTrashedNotification',
  SAVE_FORMS: 'admin.globalSettings.saveForms',
  SAVE_FILE_FORM: 'admin.globalSettings.saveFileForm',
  DELETE_FILE_FORM: 'admin.globalSettings.deleteFileForm',
  GET_GLOBAL_SETTINGS: 'admin.globalSettings.getGlobalSettings',
  UPDATE_API_KEY: 'admin.globalSettings.updateSamApiKey',
  UPDATE_KEEP_TRASHED_NOTIFICATIONS:
    'admin.globalSettings.updateKeepTrashedNotification',
  UPDATE_ACCEPT_QUOTE_REQUESTS:
    'admin.globalSettings.updateAcceptQuoteRequests',
  SAVE_EXPORT_LIMITS: 'admin.globalSettings.saveExportLimits',
  GET_DEFAULT_SAP_REQUESTS: 'admin.globalSettings.getDefaultSAPRequests',
  GET_DEFAULT_SAM_REQUESTS: 'admin.globalSettings.getDefaultSAMRequests',
}

export const TOOLTIPS_MANAGEMENT = {
  GET_TOOLTIPS: 'admin.tooltipsManagement.getTooltips',
  CREATE: 'admin.tooltipsManagement.createTooltip',
  UPDATE: 'admin.tooltipsManagement.createTooltip',
  DELETE: 'admin.tooltipsManagement.deleteTooltip',
}

export const BANNER_MANAGEMENT = {
  //api/admin/tooltipsManagement/banner/publishBanner.js
  PUBLISH: 'admin.bannerManagement.publishBanner',
  GET_BANNER: 'admin.bannerManagement.getPublishedBanner',
  UNPUBLISH: 'admin.bannerManagement.unpublishBanner',
}

export const ONBOARDING_VIEW_MANAGEMENT = {
  CREATE: 'admin.onboardingViewManagement.create',
  GET: 'admin.onboardingViewManagement.getList',
  DELETE_ONBOARDING: 'admin.onboardingViewManagement.deleteOnboarding',
  DELETE_STEP: 'admin.onboardingViewManagement.deleteStep',
}

export const ADMIN_ROLES = {
  GET_ROLES: 'admin.adminPortalRoles.getRoles',
  CREATE_ROLE: 'admin.adminPortalRoles.create',
  DELETE_ROLE: 'admin.adminPortalRoles.delete',
  UPDATE_ROLE: 'admin.adminPortalRoles.update',
  UPDATE_PERMISSIONS: 'admin.adminPortalRoles.updatePermissions',
}

export const SAVED_SEARCHES = {
  GET: 'savedSearch.getList',
  UPDATE: 'savedSearch.adminEdit',
  DELETE: 'savedSearch.delete',
  DELETE_BULK: 'savedSearch.deleteBulk',
  TOGGLE_WATCH: 'savedSearch.toggleWatch',
  GET_STATS: 'savedSearch.getStats',
}

export const LEARNWORLDS = {
  GET_ITEM_LEVELS: 'learnWorlds.getItemLevels',
  SAVE_COURSE_LEVELS: 'learnWorlds.saveCourseLevels',
}

export const TEAMING_OPPORTUNITIES = {
  GET_LIST: 'teamingOpportunities.getList',
  FIND_ONE: 'teamingOpportunities.findOne',
  UPDATE_STATUS: 'teamingOpportunities.updateStatus',
  UPDATE: 'teamingOpportunities.update',
};