import React from 'react'
import connect from '../connect'
import Typography from '../../../components/common/Typography'
import { intlDateFormat, isExpired } from '../../../utils/dateUtils'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { InlineContainer } from '../../../components/common/Containers'
import { RouterLink } from '../../../components/common/Buttons'
import { Avatar } from '../../../components/common/Cards'
import StatusChip from './Status'
import ModalSeats from './ModalSeats'
import { useTrans } from '../../../services/i18n'
import premiumIcon from '../../../components/assests/premium-icon.png'
import { Button, Grid } from '@material-ui/core'
import { ReactComponent as EnterpriceIcon } from '../../../components/assests/Enterprise-icon.svg'
import dayjs from 'dayjs'
import {
  ACCOUNT_FEATURES,
  FEATURES_SHORT_NAMES,
} from '../../../constants/subscriptionSettings'
import { selectUserPermissions, isEnabled } from '../../../selectors'
import { useSelector } from 'react-redux'

const Item = ({ item, authUser }) => {
  const getLink = item => `/organizations/${item.id}`
  const [contactOpen, setContactOpen] = React.useState(false)
  const { trans } = useTrans()
  const today = dayjs()
  const permissions = useSelector(selectUserPermissions)

  return (
    <TableRow>
      <ModalSeats
        open={contactOpen}
        onClose={() => setContactOpen(false)}
        item={item}
        refreshList={true}
      />
      <TableCell align="left">
        <InlineContainer spacing={1}>
          <Avatar
            variantAvatar="rounded"
            size={40}
            value={item.orgName}
            bgColor="gray-second"
            src={item.avatarUrl}
          />
          <Typography
            weight="500"
            to={getLink(item)}
            variant="h6"
            component={RouterLink}
          >
            {item.orgName}
          </Typography>
        </InlineContainer>
      </TableCell>
      <TableCell align="left">
        <Typography variant="inherit" fontFamily="lexend">
          {/* {item.owner && (
            <span>
              {item.owner.name} {item.owner.familyName}
            </span>
          )} */}
          {item?.ownerName && (
            <div>
              <span
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block', 
                  maxWidth: '15.6rem', 
                }}
              >
                {item.ownerName.replace(/\bundefined\b/g, '').trim()}
              </span>
            </div>
          )}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <StatusChip status={item.active ? 'Active' : 'Deactivated'} />
      </TableCell>
      {isEnabled(permissions, 'organization', 'additional-seats') && (
        <TableCell>
          {!(authUser.role === 'manager' || authUser.role === 'consulting') &&
          item.associatedSalesforceId ? (
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                setContactOpen(true)
              }}
              disabled={
                !isEnabled(
                  permissions,
                  'organization',
                  'additional-seats',
                  'edit'
                )
              }
            >
              {trans('add-seats')}
            </Button>
          ) : (
            item.licenses
          )}
        </TableCell>
      )}

      <TableCell align="left">
        <Typography variant="inherit" fontFamily="lexend">
          {intlDateFormat(item.afpdsExpirationDate)}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography variant="inherit" fontFamily="lexend">
          {intlDateFormat(item.createdAt)}
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Grid
          container
          direction="row"
          spacing={1}
          style={{ alignItems: 'center' }}
        >
          {item?.accountTier !== ACCOUNT_FEATURES.LIMITED && (
            <Grid item>
              <img src={premiumIcon} alt="" />
            </Grid>
          )}
          <Grid item style={{}}>
            <Typography variant="body2" fontFamily="lexend" weight="500">
              {trans(FEATURES_SHORT_NAMES[item?.accountTier])}
            </Typography>
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  )
}

export default connect(Item)
