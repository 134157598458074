import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'
import { API, SCOPE_REQUESTED_QUOTES } from '../../constants'
import { INITIAL_LIST_STATE_EXTENDED } from '../helpers/common'
import {
  extendListBuilder,
  generateListActions,
} from '../helpers/listActionsHelpers'
import { application } from '../../services/application'
import { ANY_OF_THOSE } from '../../constants/filter'

export const INITIAL_STATE = {
  ...INITIAL_LIST_STATE_EXTENDED,
  order: ['createdAt', 'desc'],
  requestedQuoteDetail: null,
  filters: {
    ...INITIAL_LIST_STATE_EXTENDED.filters,
    fields: {
      ...INITIAL_LIST_STATE_EXTENDED.filters.fields,
      status: {
        type: ANY_OF_THOSE,
        value: ['pending', 'completed'],
      },
    },
  },
}

//Actions
const listActions = generateListActions({
  scope: SCOPE_REQUESTED_QUOTES,
  apiMethod: {
    GET_LIST: API.REQUESTED_QUOTES.GET_LIST,
  },
  getStore: store => store.requestedQuotes,
})

const changeStatus = createAsyncThunk(
  'requestedQuotes/changeStatus',
  params => {
    return application.call(API.REQUESTED_QUOTES.CHANGE_STATUS, params)
  }
)
function onChangeStatus(state, action) {
  const copy = [...state.items]
  const index = copy.findIndex(i => i.id === action.payload.id)
  if (index !== -1) {
    copy[index].status = action.payload.status
    Object.assign(state, {
      items: [...copy],
    })
  }
  if (state.requestedQuoteDetail) {
    const copyDetail = { ...state.requestedQuoteDetail }
    copyDetail.status = action.payload.status
    Object.assign(state, {
      requestedQuoteDetail: { ...copyDetail },
    })
  }
}

const deleteRequestedQuote = createAsyncThunk(
  'requestedQuotes/deleteRequestedQuote',
  params => {
    return application.call(API.REQUESTED_QUOTES.DELETE, params)
  }
)
function onDeleteRequestedQuote(state, action) {
  const copy = [...state.items]
  const index = copy.findIndex(i => i.id === action.payload.id)
  if (index !== -1) {
    copy.splice(index, 1)
    Object.assign(state, {
      items: [...copy],
      count: state.count - 1,
    })
  }
}

const findOne = createAsyncThunk('requestedQuotes/findOne', params => {
  return application.call(API.REQUESTED_QUOTES.FIND_ONE, params)
})
function onFindOne(state, action) {
  Object.assign(state, {
    requestedQuoteDetail: action.payload,
  })
}

const addRequestedQuote = createAsyncThunk('requestedQuotes/create', params => {
  return application.call(API.REQUESTED_QUOTES.CREATE, params)
})

const updateRequestedQuote = createAsyncThunk(
  'requestedQuotes/update',
  params => {
    return application.call(API.REQUESTED_QUOTES.UPDATE, params)
  }
)

const saveResponse = createAsyncThunk(
  'requestedQuotes/saveResponse',
  params => {
    return application.call(API.REQUESTED_QUOTES.SAVE_RESPONSE, params)
  }
)

const assignUser = createAsyncThunk('requestedQuotes/assignUser', params => {
  return application.call(API.REQUESTED_QUOTES.ASSIGN_USER, params)
})

const getStaffUsers = createAsyncThunk('requestedQuotes/getStaffUsers', () => {
  return application.call(API.REQUESTED_QUOTES.GET_STAFF_USERS)
})
function onGetStaffUsers(state, action) {
  Object.assign(state, {
    users: action.payload,
  })
}

export const actions = {
  ...listActions,
  changeStatus,
  findOne,
  deleteRequestedQuote,
  addRequestedQuote,
  updateRequestedQuote,
  saveResponse,
  assignUser,
  getStaffUsers,
}

export default createReducer(INITIAL_STATE, builder => {
  return extendListBuilder(builder, actions)
    .addCase(changeStatus.fulfilled, onChangeStatus)
    .addCase(deleteRequestedQuote.fulfilled, onDeleteRequestedQuote)
    .addCase(findOne.pending, (state, action) => {
      Object.assign(state, { requestedQuoteDetail: null })
    })
    .addCase(findOne.fulfilled, onFindOne)
    .addCase(getStaffUsers.fulfilled, onGetStaffUsers)
})
